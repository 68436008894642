    <div nz-row nzJustify="stretch" nzType="flex" style="flex-wrap: nowrap;" *ngIf="this.hasValidProvider">
      <!-- Message Composer Extras Buttons-->
      <div nz-col nzSpan="2">
        <div class="message-composer-extras">
          <button 
            nzType="primary" 
            nz-button (click)="AttachmentClicked()" 
            nz-tooltip [nzTooltipTitle]="this.messageAttachment ? attachmentPopover : 'Add Attachment'"
            [disabled]="contactDTO.contact.optedOut">
              <i class="far" [ngClass] ="messageAttachment ? 'fa-trash':'fa-paperclip'"></i>
          </button>

          <button 
            nzType="primary" 
            nz-tooltip nzTooltipTitle="Insert Canned Response"
            nz-button (click)="showReplyModal=true;" 
            [disabled]="contactDTO.contact.optedOut">
              <i class="far fa-reply-all"></i>
          </button>

          <button 
            *ngIf="this.authnz.currentSecurityProvider.canAccessDripCampaigns()" 
            nzType="primary"
            nz-tooltip nzTooltipTitle="Apply Drip Campaign"
            nz-button (click)="showDripModal=true;" 
            [disabled]="contactDTO.contact.optedOut">
              <i class="far fa-tint"></i>
          </button>

          <button 
            nzType="primary" 
            nz-tooltip nzTooltipTitle="Configure Signature"
            nz-button
            nz-popover nzPopoverTrigger="click" [nzPopoverContent]="signaturePopover" 
            [(nzPopoverVisible)]="signaturePopoverVisible"
            [disabled]="contactDTO.contact.optedOut">
              <nz-badge
                nzDot
                [nzShowDot]="this.activeSignature !== null"
                [nzTitle] = "this.activeSignature?.text"
                >
                <i class="far fa-file-signature ant-btn-primary"></i>
              </nz-badge>
          </button>

          <ng-template #signaturePopover>
            <div class="signaturePopover">
              <nz-radio-group
                [ngModel]="activeSignature"
                (ngModelChange)="signatureSelected($event)"
                >
                  <label 
                    nz-radio 
                    [nzValue]="null">
                      <i class="far fa-ban"></i>No Signature
                  </label>
                  <label 
                    *ngFor="let sig of this.signatures.availableSignatures | async" 
                    nz-radio 
                    [nzValue]="sig">
                      <i class="far" [ngClass]="sig.icon"></i>
                      <span class="signatureText">{{sig.text}}</span>
                      <button 
                        *ngIf="sig.type == 'local'"
                        nz-button
                        (click)="this.signatures.removeLocal(sig)">
                          <i class="far fa-trash localSignature"></i>
                      </button>
                  </label>
              </nz-radio-group>
              <nz-divider></nz-divider>
              <div class="container">
                <input type="text" nz-input #inputElement />
                <a class="add-item" (click)="this.signatures.addLocal(inputElement.value)">
                  <i nz-icon nzType="plus"></i>
                  Add Signature (for use on this device only)
                </a>
              </div>
              
            </div>
          </ng-template>

          <button 
          nzType="primary" 
          nz-tooltip nzTooltipTitle="Emoji"
          nz-button
          nzPopoverPlacement="leftBottom" 
          nz-popover nzPopoverTrigger="click" [nzPopoverContent]="emojiPopover" 
          [(nzPopoverVisible)]="emojiPopoverVisilble">
              <i class="far fa-smile ant-btn-primary"></i>
        </button>

        <ng-template #emojiPopover>
          <div class="signaturePopover">
            <emoji-mart emoji="" title="" darkMode="false" (emojiClick)="appendEmoji($event)"></emoji-mart>
            
          </div>
        </ng-template>
        </div>
        <ng-template #attachmentPopover>
          Click to remove file - {{messageAttachment?.filename}}
        </ng-template>
      </div>

      <!-- Preview attached image -->
      <div *ngIf="messageAttachment?.data" nz-col nzSpan="4">
        <img class="mms-composer-image-preview" [src]="messageAttachment.data"
          (click)="openPhoto(messageAttachment)">
      </div>

      <!-- Message composer Text box -->
      <div nz-col nzFlex="auto">
        <ng-container *ngIf="!contactDTO.contact.optedOut; else optedOut">
          <div class="textarea-container">
            <textarea 
              (click)="findCursor($event)"
              id="textarea"
              placeholder="Compose your message here or paste an image from your clipboard..." 
              [(ngModel)]="newMessage" 
              (change) = "messageTextChanged($event)" 
              autocorrect="on"
              (keydown.shift.enter)="send()" 
              (paste)="onPaste($event)"
              [nzAutosize]="true">
            </textarea>
            <div *ngIf="activeSignature" class="signature">{{activeSignature.text}}</div>
          </div>
          
        </ng-container>
        <ng-template #optedOut>
          <div class="opt-out-div">
            <nz-alert
              class="opt-out-message" 
              nzType="error" 
              [nzMessage]="optOutMessage" 
              nzShowIcon 
              nzBanner
              nzDescription="Contact may need to reply START to allow messages from your phone number again.">
            </nz-alert>
            <ng-template #optOutMessage>
              Contact Opted Out at {{contactDTO.contact.optedOutAt | date:'short'}}
            </ng-template>
          </div>
        </ng-template>
      </div>

      <!-- Send Button -->
      <div nz-col nzSpan="4">
        <button class="send-btn" nzType="primary" nz-button (click)="send()" [disabled]="contactDTO.contact.optedOut">
          <i class="far fa-paper-plane"></i><br />
          <small>(Shift + Enter)</small>
        </button>
      </div>
    </div>
  
    <div nz-row nzJustify="stretch" nzType="flex" *ngIf="!this.hasValidProvider">
      <div nz-col nzSpan="3"></div>
      <div nz-col nzSpan="18">
        <nz-alert
          nzType="warning"
          nzMessage="Please visit the My Profile page to configure a VoIP provider in order to send and receive messages"
          [routerLink]="['/profile']"
        >
        </nz-alert>
      </div>
      <div nz-col nzSpan="3"></div>
    </div>


<app-mms-uploader [isVisible]="showMMSModal" [contact]="contactDTO.contact" (closeMMSUploaderEvent)="closeMMSUploader()"></app-mms-uploader>

<nz-modal [(nzVisible)]="showReplyModal" nzTitle="Canned Responses" [nzFooter]="null" [nzClosable]="true" (nzOnCancel)="showReplyModal=false;">
  <ng-container *nzModalContent>

  <p class="text-center">Prefill your reply with a canned response by clicking one below.</p>
  <ul class="txb-list">
    <li *ngFor="let response of this.textable.cannedResponses$ | async" (click)="applyCannedResponse(response)" class="list-item list-item-single-select">
      <nz-row>
        <nz-col nzSpan="6">{{response.name}}</nz-col>
        <nz-col nzSpan="12">{{response.body}}</nz-col>
        <nz-col nzSpan="6" *ngIf="response?.attachments?.length > 0">
          <i nz-icon nzType="paper-clip"></i>&nbsp;
          {{ response?.attachments[0]?.filename }}
        </nz-col>
      </nz-row>

    </li>
  </ul>
  </ng-container>
</nz-modal>

<nz-modal [(nzVisible)]="showDripModal" [nzTitle]="dripModalTitle" [nzContent]="dripModalContent"
  [nzFooter]="dripModalFooter" [nzClosable]="true" (nzOnCancel)="closeDripModal()">
  <ng-template #dripModalTitle>Apply Drip Campaign to Contact</ng-template>

  <ng-template #dripModalContent>
    <div *ngIf="!activeDripCampaign">
      <p class="text-center">Please choose a drip campaign to apply to the contact. Once chosen, you will be able to
        preview the send date / times of each drip and manually adjust as needed. </p>
      <ul class="txb-list">
        <li *ngFor="let drip of this.textable.dripCampaigns$ | async" (click)="activeDripCampaign=drip"
          class="list-item list-item-single-select">
          <b>{{drip.name}}</b> | {{drip.drips.length}} Drips
        </li>
      </ul>
    </div>

    <div *ngIf="activeDripCampaign">

        <p class="modal-card-text">Choose an option to control when the drip campaign is applied.</p>

        <nz-select [(ngModel)]="dripStartOptions" class="drip-modal-select">
          <nz-option nzValue="{{DripStartOptions.NOW}}" nzLabel="Now"></nz-option>
          <nz-option nzValue="{{DripStartOptions.DELAY}}" nzLabel="Delay"></nz-option>
          <nz-option nzValue="{{DripStartOptions.EXACT}}" nzLabel="Exact Datetime"></nz-option>
        </nz-select>

        <div *ngIf="dripStartOptions == DripStartOptions.DELAY">
          <nz-row>
            <nz-col nzSpan="8">
              <nz-input-number [nzMin]="1" [nzPlaceHolder]="1" [(ngModel)]="dripDelay"></nz-input-number>
            </nz-col>
            <nz-col nzSpan="16">
              <nz-select nzPlaceHolder="Select time unit" [(ngModel)]="dripDelayUnit">
                <nz-option nzValue="{{DripDelayUnit.MINUTE}}" nzLabel="Minutes"></nz-option>
                <nz-option nzValue="{{DripDelayUnit.HOUR}}" nzLabel="Hours"></nz-option>
                <nz-option nzValue="{{DripDelayUnit.DAY}}" nzLabel="Days"></nz-option>
              </nz-select>
            </nz-col>
          </nz-row>
        </div>

        <div *ngIf="dripStartOptions == DripStartOptions.EXACT">
          <tz-date-time-picker [formGroup]="frmDripSelectedDateTime" timeZoneFieldName="scheduleTimezone"
            dateFieldName="scheduleTime"></tz-date-time-picker>
          <span *ngIf="frmDripSelectedDateTime?.errors?.dateError" class="drip-model-error">Please select a
            date in the future.</span>
        </div>


      <nz-divider nzText="Preview Drips"></nz-divider>
      <div class="drip-modal-content-container"> 
        <ul class="txb-list">
          <li *ngFor="let drip of activeDripCampaign.drips; let dripCount = index;" class="list-item">
            <nz-row>
              <nz-col nzSpan="16">
                <h3>Drip #{{(dripCount + 1)}}</h3>
                <p>{{drip.message}}</p>
                <small>{{calculateApproxSendDate(dripCount,drip.interval,drip.intervalType)}}</small><br>
                <ng-container *ngIf="drip.attachments?.length > 0">
                  <i nz-icon nzType="paper-clip"></i>&nbsp;
                  <a (click)="openPhoto(drip?.attachments[0])">{{ drip?.attachments[0]?.filename }}</a>
                </ng-container>
              </nz-col>
              <nz-col *ngIf="drip.attachments?.length > 0" nzSpan="8">
                <ng-container *ngIf="mms.isImageFile(drip?.attachments[0].contentType)">
                  <img class="mms-preview" [src]="mms.getAttachmentURL(drip?.attachments[0])"
                    (click)="openPhoto(drip?.attachments[0])">
                </ng-container>
              </nz-col>
            </nz-row>
          </li>
        </ul>
      </div>

      <h4 class="muted">All messages will be created as scheduled messages based on their set interval from now. Unless
        Drip Deletion is disabled, contact replies will remove any scheduled drips automatically.</h4>
    </div>
  </ng-template>

  <ng-template #dripModalFooter>
    <ng-container *ngIf="!activeDripCampaign">
      <button nz-button nzType="default" (click)="closeDripModal()">Close</button>
    </ng-container>
    <ng-container *ngIf="activeDripCampaign">
      <button nz-button nzType="dashed" (click)="activeDripCampaign=null;">Go Back</button>
      <button nz-button [disabled]="!isValidDate" nzType="primary" (click)="handleOk()" [nzLoading]="isConfirmLoading"
        nz-popconfirm nzPopconfirmTitle="Are you sure you want to apply this drip campaign?"
        (nzOnConfirm)="applyDripCampaign(activeDripCampaign);activeDripCampaign=null;showDripModal=false;">Apply
        Campaign</button>
    </ng-container>
  </ng-template>
</nz-modal>

