import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, Component, ContentChildren, DoCheck, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Location } from '@angular/common';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthNZService } from 'src/app/core/authnz.service';
import { BehaviorSubject, combineLatest, merge, Observable, Subject, Subscriber, Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TextableService } from 'src/app/services/textable.service';
import * as moment from 'moment-timezone';
import { saveAs } from 'file-saver';
import { vars } from 'src/app/app.constants';
import { getLogger } from 'src/shared/logging';
import { trackByDocumentId } from 'src/app/functions';
import { BackendService } from 'src/app/services/backend.service';
import { ContactsService } from 'src/app/services/contacts.service';
import { VisibilityService } from 'src/app/services/visibility-service.service';
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling"
import { debounce, debounceTime, delay, distinctUntilChanged, filter, first, map, mergeAll, skip, skipUntil, startWith, tap, withLatestFrom} from 'rxjs/operators';
import { isTextableContact, TextableContact } from 'src/app/backported_types/contact';
import { TextableMessage } from 'src/app/backported_types/messaging';
import { NANPRegexStrict, PhoneNumber } from '@shared/PhoneNumber';
import { MessagingService } from 'src/app/services/messaging.service';
import { ScrollableContentRegion } from "src/classes/ScrollableContentRegion"
import "src/polyfills/FirstEmitAsPromise";
import { ContactDTO, isContactDTO } from 'src/classes/contactDTO';
import { toBoolean } from '@shared/toBoolean';
import { TextableDate } from 'src/classes/TextableDate';
import { DateRangeValidator } from 'src/app/form_validators/DateRangeValidator';

const log = getLogger("ConversationsComponent");


type DisplayConversationList = {
  contacts: ContactDTO[];
  displayType: "normal" | "loading" | "empty" | "no-results";
}

type ConversationFilterSet = {
  /**
   * Text filter value for the conversation list
   */
  searchText: string
  /**
   * Filter Property to show only all unread messages
   */
  readStatus: "read" | "unread" | "all",
  archiveStatus: "unarchived" | "archived" | "all",
  sortBy: "date" | "name" | "number"
  /**
   * Support excluding contacts which have an unknown last_message_date
   */
  blockStatus: "blocked" | "unblocked" | "all"
  unknownDate: boolean
}

@Component({
  selector: 'app-conversations',
  templateUrl: './conversations.component.html',
  styleUrls: ['./conversations.component.scss'],
  host: {
    "class": "app-view app-view-two-pane"
  }
})
export class ConversationsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren('messageList') rightPaneChanges: QueryList<CdkVirtualScrollViewport>;
  @ViewChild('rightPane') rightPaneRef: ElementRef;  


  /**
   * Local instance pointer to the utility function `trackByDocumentId`
   */
  public trackByDocumentId = trackByDocumentId;


  /**
   * Object representing the view model of the active conversation.
   * This is really just a TextableContact, with an additional 
   * property representing an observable of the active messages 
   * for the conversation
   * 
   */
  public activeConversation: ContactDTO

  public spotlightConversation: ContactDTO;
  /**
   * Controls whether the new conversation drawer is visible
   */
  public showNewConversationDrawer: boolean = false;

  /**
   * Controls whether user guides are visible
   * 
   * TODO: TXBDEV-835 - refactor this to a service instead of individual modals.
   */
  public guideVisibleBool = false;


  /**
   *  Indicates active loading of the conversation list
   */
  public loadingConversation: boolean = false;


  /**
   * Form group used in the new conversation drawer
   */
  public frminitiateConversation: FormGroup;
  /**
   * Loading indicator for the new conversation drawer
   */
  public loadingInitiateConversation: boolean = false;


  /**
   * Controls whether the conversation export modal is visible
   */
  public showExportModal = false;
    /**
   * Form group used in the export conversation modal
   */
  public frmExport: FormGroup;
  /**
   * Controls whether the notes modal is visible
   */
  public addNotesModal = false;
  /**
   * Form group for add notes modal
   */
  public frmAddNotes:FormGroup;

  //Vars for checkbox feature.
  public allChecked = false;
  public indeterminate = false;
  public updateAllChecked = new Subject<boolean>();
  public filterFormGroup: FormGroup;
  public applyFilter = new Subject<void>();
  /**
   * Key-value pair where keys are document Ids of contacts
   * and values are booleans whether or not the contact is "checked"
   */
  public checkedContacts = new BehaviorSubject<Record<string, boolean>>({});
  /**
   * Number of conversations checked across all pages
   */
  public numberOfChecked: number;

  public checkedContactIDs = this.checkedContacts.pipe(
    withLatestFrom(this.contactService.conversations$),
    map(([checkedContacts,contacts])=>{
      return Object.entries(checkedContacts).filter(([k,v])=>v).map(([k,v])=>k).map(
        (id)=>contacts.contacts.find(c=>c.contact.id == id)
      )
    })
  )
  
  /**
   * Conversations to be displayed; limited by conversationListPageSize
   * 
   * This is updated by both subscription events from the Contact Service
   * and by changes to the filter value on this page.
   */
  public displayConversationList: Subject<DisplayConversationList>;
  
  /**
   * Page size of `displayConversationList`
   * 
   * TODO: TXBDEV-1690; allow this to be set dynamically. For now, default to static 10
   */
  public conversationListPageSize = 10;
  /**
   * Current page of the 
   */
  public conversationListPageIndex =  new BehaviorSubject<number>(1)
    
  public searchFilterText = new BehaviorSubject<string>('');
  /**
   * Total size of the paginated display conversations.
   */
  public conversationListSize: number;
  /**
   * Index of the list item which is being hovered by the cursor
   */
  public hoveredConvoListIndex: number;

  
  public showUnread: boolean;

  /**
   * Reference to the ScrollableContentRegion instance that
   * manages the scroll position of the cdk-virtual-scroll-viewport 
   */
  public scrollableMessages: ScrollableContentRegion;

  constructor(
    private authnz: AuthNZService, 
    private textable: TextableService,
    private route: ActivatedRoute,
    private backendService: BackendService,
    public contactService: ContactsService,
    private visibilityService: VisibilityService,
    private messagingService: MessagingService,
    private router: Router,
    private location: Location
  ) { 
    this.scrollableMessages = new ScrollableContentRegion(visibilityService);
  }
  ngOnDestroy(): void {
    this.scrollableMessages.destroy();
  }

  ngAfterViewInit(): void {
    this.visibilityService.elementInSight(this.rightPaneRef.nativeElement).subscribe((isVisible)=>{
      this.activeConversation?.setConversationHasFocus(isVisible);
    })
    this.rightPaneChanges.changes.subscribe((c: QueryList<CdkVirtualScrollViewport>)=>{
      if (c.first) {
        this.scrollableMessages.setCDKViewport(c.first);
      }
    })
  }

  ngOnInit() {
    this.route.params.subscribe(async params => {
      if(params['id']){
        try {
          const contact = await this.contactService.getContactById(params['id']);
          if (contact.isArchived) {
            await contact.unarchive();
          }
          await this.setActiveConversation(contact);
        }
        catch (err) {
            this.router.navigate(['conversation-results']);
        } 
      }
    });
    this.initializeForms();
   
    this.authnz.contextChanged.subscribe(({activeNumberProfile, previousActiveNumberProfile})=>{
      if (previousActiveNumberProfile.phone_number !== activeNumberProfile.phone_number) {
        log.debug("Changing active number from '" +  previousActiveNumberProfile.phone_number + "' to '" + activeNumberProfile.phone_number + "'");
        this.clearContext();
      }
    });
    this.setupConversationList();
    this.setupCheckedHandlers();
  }

  /**
   * Links the ContactService observable with the Conversation Filter box and the pagination selector
   * 
   * Pushes the resulting filtered/sorted/paginated contacts to displayConversationList  
   */
  private setupConversationList() {

    /**
     * Create an intermediate observable that debounces and emits
     * on filter set changes
     */
    const filterUpdates = this.filterFormGroup.valueChanges
      .pipe(
        map(()=>{
          return this.filterFormGroup.value
        }),
        startWith({})
      )
      .pipe(
        debounceTime(200),
        map((filterValues): ConversationFilterSet=>{
          return {
            readStatus: filterValues.readStatus || "all", 
            archiveStatus: filterValues.archiveStatus || "unarchived",
            searchText: filterValues.searchText || '',
            sortBy: filterValues.sortBy || "date",
            blockStatus: filterValues.blockStatus || "unblocked",
            unknownDate: toBoolean(filterValues.unknownDate,true)
          }
        }),
        tap((filterSet)=>{
          log.debug("Filters Changed", filterSet)
        })
      )

    /**
     * Create an intermediate observable that emits the latest filtered and sorted
     * set of contacts
     */
    const filteredAndSorted = combineLatest([
      this.contactService.contacts$,
      filterUpdates
    ]).pipe(
      map(([conversations, filterSet]): DisplayConversationList=>{
        if (conversations.isLoading) {
          return {
            contacts: [],
            displayType: "loading"
          }
        }

        let filteredContacts:  ContactDTO[] = conversations.contacts;
        let wasFiltered = false;

        switch (filterSet.blockStatus) {
          case "all":
            filteredContacts = filteredContacts;
            break;
          case "blocked":
            filteredContacts = filteredContacts.filter(c=>c.isBlocked)
            wasFiltered = true;
            break;
          case "unblocked":
            filteredContacts = filteredContacts.filter(c=>!c.isBlocked)
            wasFiltered = true;
            break;
        }

        switch (filterSet.archiveStatus) {
          case "all":
            filteredContacts = filteredContacts;
            break;
          case "archived":
            filteredContacts = filteredContacts.filter(c=>c.isArchived)
            wasFiltered = true;
            break;
          case "unarchived":
            filteredContacts = filteredContacts.filter(c=>!c.isArchived)
            break;
        }

        if (!filterSet.unknownDate) {
          filteredContacts = filteredContacts.filter(c=>typeof c.contact.last_message_date == "number" && c.contact.last_message_date > 0)
          wasFiltered = true;
        }

        switch(filterSet.readStatus) {
          case "read":
            filteredContacts = filteredContacts.filter(c=>c.readStatus.value.isRead)
            wasFiltered = true;
            break;
          case "unread":
            filteredContacts = filteredContacts.filter(c=>! c.readStatus.value.isRead)
            wasFiltered = true;
            break;
          case "all":
            // nothing to do?
            break;
        }

        if (filterSet.searchText.length > 0) {
          filteredContacts = filteredContacts.filter(c=>c.testMatchesSearchString(filterSet.searchText))
          wasFiltered = true;
        }

        return {
          contacts: filteredContacts.sort((a,b)=>{
              switch (filterSet.sortBy){
                case "date":
                  return a.compareDate(b);
                case "name":
                  return a.compareName(b);
                case "number":
                  return a.compareNumber(b);
              }
            }),
          displayType: filteredContacts.length > 0 ? "normal" : ( 
            wasFiltered ? "no-results" : "empty")
        }
      }),
      tap(c=>{
        if (this.conversationListSize != c.contacts.length) {
          this.conversationListSize = c.contacts.length
          this.conversationListPageIndex.next(1);
        }
        if (! c.contacts.includes(this.activeConversation)) {
          /**
           * TODO: This action might be contentious
           * 
           * In some cases, a user might want to search / filter the conversation list whilst keeping the current conversation active
           * and in some cases, the user might not want this to happen.
           */ 
          //this.setActiveConversation(null);
        }
      })
    );

    /**
     * Setup a subscription to render a paginated view of conversations to the 
     * list.
     * 
     * If there's an active conversation and it's not on the current page, 
     * we put it at the top of the current page's view
     */
    this.displayConversationList = new Subject<DisplayConversationList>();
    
    combineLatest([
        filteredAndSorted,
        this.conversationListPageIndex
      ]).pipe(
      map((v): DisplayConversationList =>{
        const contacts = v[0].contacts
        const startIndex = (v[1]-1) * this.conversationListPageSize
        const endIndex = (Math.min((v[1]) * this.conversationListPageSize,contacts.length));
        log.debug(`Displaying conversations from ${startIndex}  to ${endIndex-1}`);
        let pageSlice = contacts.slice(startIndex, endIndex);
        this.spotlightConversation = !pageSlice.includes(this.activeConversation) ? this.activeConversation : null

        return {
          contacts: pageSlice,
          displayType: v[0].displayType
        }
      })
    ).subscribe(this.displayConversationList)
  }
  
  public resetFilters() {
    this.filterFormGroup.setValue({
      searchText: "",
      readStatus: "all",
      archiveStatus: "unarchived",
      sortBy: "date",
      unknownDate: true,
      blockStatus: "unblocked"
    })
  }

  /**
   * Links the displayConversationList observable with the handlers for "checking" individual 
   * conversations and for "checking" all conversations.
   */
  private setupCheckedHandlers() {
    /**
     *Setup a subscription to manage the checked state of the conversations
     */
    combineLatest([
      this.updateAllChecked,
      this.displayConversationList
    ])
     .subscribe(([allChecked,v])=>{
       const newChecked: Record<string,boolean> = {};
       v.contacts.forEach((item) => {
         newChecked[item.contact.id] = allChecked
       });
       this.checkedContacts.next(newChecked);
     });

   /**
    * Setup a subscription to manage the checked state of the conversations
    */
   combineLatest([
    this.checkedContacts,
    this.displayConversationList
   ])
     .subscribe(([checkedContacts,contacts])=>{
       for (let e of Object.entries(checkedContacts)) {
         if (!contacts.contacts.find(c=>c.contact.id == e[0])) {
           delete checkedContacts[e[0]];
         }
       }

       if (contacts.contacts.length > 0) {
         this.allChecked = contacts.contacts.every(item => checkedContacts[item.contact.id]);
         this.indeterminate = contacts.contacts.some(item => checkedContacts[item.contact.id]) && !this.allChecked;
       } else {
         this.numberOfChecked = 0;
         this.allChecked = false;
       }
       /**
        * get the count of all keys of checkedContacts which are true
        */
       this.numberOfChecked = Object.entries(checkedContacts).filter(k=>k[1]).length 
     })
  }

  public setContactCheckStatus(id: string, checked: boolean) {
    this.checkedContacts.next({
      ...this.checkedContacts.value,
      [id]: checked
    })
  }

  /**
   * Clears the context of the conversation view: filter text, 
   * selected conversations, active conversation, etc
   */
  async clearContext() {
    this.searchFilterText.next('');
    await this.setActiveConversation(null);
    this.checkedContacts.next({});
    this.conversationListPageIndex.next(1);
  }

  initializeForms() {

    this.filterFormGroup = new FormGroup({
      "searchText": new FormControl(''),
      "readStatus": new FormControl("all"),
      "archiveStatus": new FormControl("unarchived"),
      "sortBy": new FormControl("date"),
      "blockStatus": new FormControl("unblocked"),
      "unknownDate": new FormControl(true)
    })

    this.frminitiateConversation = new FormGroup({
      "phone_number": new FormControl(null, [Validators.required])
    });

    this.frmExport = new FormGroup({
      "range": new FormControl(null, [
        Validators.required,
        DateRangeValidator(30)
      ]),
      "contactId": new FormControl(null, [
        Validators.required
      ]),
      "startDate": new FormControl(null),
      "endDate": new FormControl(null),
      "format": new FormControl(null, [
        Validators.required
      ]),
      "timezone": new FormControl(Intl.DateTimeFormat().resolvedOptions().timeZone),
    });

    this.frmAddNotes = new FormGroup({
      "body": new FormControl(null, [
        Validators.required,
        Validators.minLength(2)
      ])
    });
  }

  public async bulkMarkUnread(){
    this.loadingConversation = true
    let contactArray = Object.entries(this.checkedContacts.value)
      .filter(([id,checked])=>checked)
      .map(([id,checked])=>this.contactService.contacts$.value.contacts.find(x=>x.contact.id == id))
    this.updateAllChecked.next(false)
    await Promise.all(contactArray.map(async c=>await c.markUnread()));
  }

  public async bulkArchive() {
    this.loadingConversation = true
    let contactArray = Object.entries(this.checkedContacts.value)
      .filter(([id,checked])=>checked)
      .map(([id,checked])=>this.contactService.contacts$.value.contacts.find(x=>x.contact.id == id))
      this.updateAllChecked.next(false)
    await Promise.all(contactArray.map(async c=>await c.archive()));
  }

  public async bulkUnarchive(){ 
    this.loadingConversation = true
    let contactArray = Object.entries(this.checkedContacts.value)
      .filter(([id,checked])=>checked)
      .map(([id,checked])=>this.contactService.contacts$.value.contacts.find(x=>x.contact.id == id))
      this.updateAllChecked.next(false)
    await Promise.all(contactArray.map(async c=>await c.unarchive()));
  }

  /**
   * Sets the active conversation to the supplied contact 
   * @param conversation a TextableContact-like object, with some additional properties
   * @returns 
   */
  async setActiveConversation(conversation: ContactDTO) {

    if (conversation?.contact.id == this.activeConversation?.contact.id) {
      log.debug("Conversation is already active, nothing to do", conversation);
      return;
    }

    if(this.activeConversation) {
      this.activeConversation.deactivate();
    }

    if(!conversation){
      log.debug("Unsetting active conversation");
      this.activeConversation = null;
      return;
    }

    if (conversation.contact.uid !== this.authnz.currentFireauthUser.uid) {
      await this.authnz.setActiveNumber(conversation.contact.uid)
    }

    conversation.activate();
    this.activeConversation = conversation;
    this.loadingConversation = true;
    log.info("setting active conversation", conversation);
    this.location.replaceState(`/conversations/${conversation.contact.firebase_document_id}`);
    
    this.scrollableMessages.setDataSource(this.activeConversation.messages);
    /*
      TODO: 
      We need to retrieve any messages with contactId of conversation.id, status of "scheduled" and a future date.
    */
  }

  ///////////////////
  // EXPORT MESSAGES
  ///////////////////
  exportMessages() {
    this.frmExport.get('startDate').setValue(new TextableDate(this.frmExport.value.range[0]).getTime());
    this.frmExport.get('endDate').setValue(new TextableDate(this.frmExport.value.range[1]).getTime())


    this.textable.exportMessages(this.frmExport.value)
      .then((blob) => {
          saveAs(blob, 'conversationExport.'+this.frmExport.value.format)
          this.handleCancel();
      }).catch(e => {
        console.log(e.error)
      })
  }

  convertTimestampToDate(conversation) {
    if (!conversation.last_message_date) {
      return 'Unknown'
    }
    return new TextableDate(conversation.last_message_date).humanize();
  }
  
  /**
   * Begins a conversation with a phone number or contact 
   * provided by the "New Conversation" drawer.
   */
  async initiateConversation(data: any){
    
    if (!data) {
      log.debug("Not initiating conversation", null);
      return;
    }
    let contact: ContactDTO;

    if(isTextableContact(data)) {
      contact = await this.contactService.getContactById(data.id);;
      log.debug("Got contact from conversation picker", contact);
    }
    else if (isContactDTO(data)) {
      contact = data;
    }
    else if (data.hasOwnProperty("phone_number")) {
      const number = new PhoneNumber(data.phone_number)
      contact = await this.contactService.getContactByPhoneNumber(number)
      if (contact) {
        log.debug("Got existing contact ", contact);
      }
      if (!contact){
        contact = await this.contactService.createContact({
          phone_number: number.ToE164(),
          full_name: number.ToE164(),
          isArchived: false,
          last_message_date: null
        })
        log.debug("Created new contact", contact);
      }
    }

    await contact.unarchive();
    await this.setActiveConversation(contact);
    this.showNewConversationDrawer=false;
    this.loadingInitiateConversation = false;
    this.frminitiateConversation.reset();
  }

  /**
   * Saves a message as a note in the message collection. This does not send.
   */
  public async addNote(){
    await this.messagingService.send(
      {
        contact: this.activeConversation.contact, 
        newMessage: this.frmAddNotes.get('body').value,
        isNote:true 
      });
    this.handleCancel()
  }

  async unarchiveChat(contact: ContactDTO) {
    await contact.unarchive();
    await this.setActiveConversation(contact);
  }

  async archiveChat(contact: ContactDTO) {
    if(this.activeConversation?.contact.id === contact.contact.id){
      await this.setActiveConversation(null);
    }
    await contact.archive();
  }

  /**
   * Sets the Contact's read_status property to false.
   */
  async markChatUnread(contact: ContactDTO){
    if(this.activeConversation?.contact.id === contact.contact.id){
      await this.setActiveConversation(null);
    }
    await contact.markUnread()
  }

  deleteChat(c: ContactDTO) {

    let contact: TextableContact;
    if (isTextableContact(c)) {
      contact = c
    }
    else {
      contact = c.contact
    }
    

    let postData = {
      contact_id: contact.firebase_document_id
    };

    this.backendService.backendPost(
      "deleteConversation",
      {},
      postData
    ).then(result => {
      if (result.error) {
        log.error("Error deleting conversation", result.error);
      } else {
        this.activeConversation = null;
      }
    })
  }

  handleCancel(): void {
    this.frmExport.reset();
    this.frmAddNotes.reset();
    this.frminitiateConversation.reset();
    this.showNewConversationDrawer=false;
    this.showExportModal = false;
    this.addNotesModal = false;
    this.guideVisibleBool = false;
  }
  
  openExportModal() {
    this.frmExport.get('contactId').setValue(this.activeConversation.contact.id);
    this.showExportModal = true;
  }

  openAddNoteModal() {
    this.addNotesModal = true;
  }

}
