import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import log from 'loglevel';
import { AuthNZService } from 'src/app/core/authnz.service';
import { TextableService } from 'src/app/services/textable.service';

export interface UserGuideConfig{
  active?:boolean;
  name:string;
  pathToSource:string;
  audience?:string[];
  sortOrder: number;
}

@Component({
  selector: 'app-user-guides',
  templateUrl: './user-guides.component.html',
  styleUrls: ['./user-guides.component.scss']
})
export class UserGuidesComponent implements OnInit {

  @ViewChild('guideContentPane', { read: ElementRef }) guideContentPane: ElementRef;
  @Input() selectedGuide;
  /**
   * Some guides are restricted to admin users only.  
   * 
   * This instance variable is updated when the active user document changes
   * according to whether the user is able to access admin features.
   */
  audienceMembership:string;

  guides:UserGuideConfig[];
  allGuides:Array<UserGuideConfig> = [
    {
      active:true,
      name: 'Number Switcher',
      pathToSource: "./assets/guides/number-switcher.md",
      sortOrder: 1
    },
    {
      active: false,
      name: 'Away Message',
      pathToSource: "./assets/guides/away-message.md",
      sortOrder: 2
    },
    {
      active: false,
      name: 'Conversations',
      pathToSource: "./assets/guides/conversations.md",
      sortOrder: 3
    },
    {
      active: false,
      name: 'Contacts',
      pathToSource: "./assets/guides/contacts.md",
      sortOrder: 4
    },
    {
      active: false,
      name: 'Blocked Senders',
      pathToSource: "./assets/guides/blocked_senders.md",
      sortOrder: 4
    },
    {
      active: false,
      name: 'Contact Lists',
      pathToSource: "./assets/guides/contact-lists.md",
      sortOrder: 5
    },
    {
      active: false,
      name: 'Canned Responses',
      pathToSource: "./assets/guides/canned-responses.md",
      sortOrder: 6
    },
    {
      active: false,
      name: 'Drip Campaign',
      pathToSource: './assets/guides/drip-campaigns.md',
      sortOrder: 7
    },
    {
      active: false,
      name: 'Blasts',
      pathToSource: './assets/guides/blasts.md',
      sortOrder: 8
    },
    {
      active: false,
      name: 'Reminders',
      pathToSource: './assets/guides/reminders.md',
      sortOrder: 9
    },
    {
      active: false,
      name: 'Admin Menu',
      pathToSource: './assets/guides/admin.md',
      audience: ['admin'],
      sortOrder: 10
    },
    {
      active: false,
      name: 'Service Accounts',
      pathToSource: './assets/guides/service-accounts.md',
      audience: ['superadmin'], // TXBDEV-1656 - Create and show this documentation; currently this audience doesn't exist, so the docs will never show
      sortOrder: 11
    },
    {
      active: false,
      name: 'Integrations',
      pathToSource: './assets/guides/integrations/integrations.md',
      sortOrder: 12
    },
    {
      active: false,
      name: 'Integration - HubSpot',
      pathToSource: './assets/guides/integrations/hubspot.md',
      sortOrder: 13
    },
    {
      active: false,
      name: 'Integration - Pipedrive',
      pathToSource: './assets/guides/integrations/pipedrive.md',
      sortOrder: 14
    },
    {
      active: false,
      name: 'Integration - Slack',
      pathToSource: './assets/guides/integrations/slack.md',
      sortOrder: 15
    },
    {
      active: false,
      name: 'Integration - Google Chat',
      pathToSource: './assets/guides/integrations/googlechat.md',
      sortOrder: 16
    },
    {
      active: false,
      name: 'Integration - MS Teams',
      pathToSource: './assets/guides/integrations/msteams.md',
      sortOrder: 17
    },
    {
      active: false,
      name: 'Integration - Webex',
      pathToSource: './assets/guides/integrations/webex.md',
      sortOrder: 18
    },
    {
      active: false,
      name: 'Integration - Webhook Relay',
      pathToSource: './assets/guides/integrations/webhookRelay.md',
      sortOrder: 19
    },
    {
      active: false,
      name: 'Integration - Salesforce',
      pathToSource: './assets/guides/integrations/salesforce.md',
      sortOrder: 20
    },
    {
      active: false,
      name: 'Integration - ZohoCRM',
      pathToSource: './assets/guides/integrations/zoho.md',
      sortOrder: 21
    },
    {
      active: false,
      name: 'Integration - Microsoft 365 (Contacts)',
      pathToSource: './assets/guides/integrations/m365.md',
      sortOrder: 21
    },
    {
      active: false,
      name: 'Integration - Google Workspace (Contacts)',
      pathToSource: './assets/guides/integrations/gsuite.md',
      sortOrder: 21
    }
  ];

  activeGuide:UserGuideConfig;

  constructor(
    private textable: TextableService,
    private authnz: AuthNZService
    ) { }

  ngOnInit() {

    if (this.allGuides.filter(x=>x.name == this.selectedGuide).length == 0) {
      log.warn("No guide defined for '" + this.selectedGuide + "' using coming-soon.md")
      this.allGuides.push({
        name: this.selectedGuide,
        pathToSource: "./assets/guides/coming-soon.md",
        sortOrder: 1
      });
    }

    this.authnz.contextChanged.subscribe(()=>{
      this.audienceMembership = this.authnz.currentSecurityProvider.canAccessAdminMenu() ? 'admin' : null;
      this.guides = this.ReplaceGuidesForEnvironment(this.allGuides)
        .filter(this.FilterGuidesForAudience.bind(this))
        .sort((a,b)=>a.sortOrder-b.sortOrder)
      this.activeGuide = this.allGuides.filter(x=>x.name == this.selectedGuide)[0];
    });
  }

  selectGuide(guide:UserGuideConfig){
    this.guideContentPane.nativeElement.scrollTop = 0;
    this.selectedGuide = guide.name;
    this.activeGuide = guide;
  }

  ReplaceGuidesForEnvironment(guides: UserGuideConfig[]): UserGuideConfig[] {
    const overrides = this.authnz.currentSecurityProvider.getUserGudeOverrides() || [];

    for(let o of overrides) {
      const i = guides.findIndex(g=>g.name == o.name);
      if (i === -1) {
        guides.splice(o.sortOrder,0,o);
      }
      else {
        guides.splice(i,1,o);
      }
    }
    return guides
  }

  FilterGuidesForAudience(value: UserGuideConfig, index: number, array: UserGuideConfig[]): boolean {
    if(value.hasOwnProperty('audience') && value.audience.length > 0){
      return value.audience.includes(this.audienceMembership);
    }else{
      return true;
    }
  }


}
